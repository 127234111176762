/**
 * @description obj数字转str数组
 * @param name
 * @returns 
 */
const getStrArr = (arr: any, key: any): any => {
    let result: any = [];
    if (arr.length > 0) {
        arr.forEach((ele: any) => {
            result.push(ele[key])
        });
    }
    return result
}
/**
 * @description 二级导航名字处理（如果是四个字，则每行显示2个字）
 * @param name
 * @returns 
 */
const getNavLeftText = (name: any): any => {
    console.log(name)
    if (name.length === 4) {
        const arr = name.split("");
        return arr[0] + arr[1] + "\n" + arr[2] + arr[3];
    } else if (name == 'HIS数据') {
        console.log(2)
        const arr = name.split("");
        console.log(arr)
        return "\n" + arr[0] + arr[1] + arr[2] + ' ' + "\n" + arr[3] + arr[4];
    } else {
        return name;
    }
}
/**
 * @description 控件题目是否未填
 * @param data{}// 控件数据
 * @returns 
 */
const IfEmpty = (data: any): any => {
    if (data.is_required) {
        if (data.type === "多选") {
            return data.value.length === 0;
        } else if (data.type === "计分单选" || data.type === "sf36计分单选") {
            return !data.value.name;
        } else if (data.type === "计分条") {
            return !data.value;
        } else if (data.type === "多选补充文字") {
            let val = true;
            for (const opt of data["options"]) {
                if (opt.value) {
                    val = false;
                }
            }
            return val;
        } else if (data.type === "单选") {
            return !data.value;
        }
    } else {
        return false
    }
}
/**
 * @description 生成题目联动数据
 * @param data{}
 * @returns 
 */
const getControlData = (data: any) => {
    if (data.type === "单选" || data.type === "下拉单选") {
        let index;
        data.options.forEach((ele: any, i: any) => {
            if (ele === data.value) {
                index = i;
            }
        });
        if (data.value) {
            data.control.index = [index];
        } else {
            data.control.index = [];
        }
    } else if (data.type === "计分单选" || data.type === "sf36计分单选") {
        let index;
        data.options.forEach((ele: any, i: any) => {
            if (ele.name === data.value.name) {
                index = i;
            }
        });
        if (data.value && data.value.name) {
            data.control.index = [index];
        } else {
            data.control.index = [];
        }
    } else if (data.type === "单选补充文字") {
        let index;
        let hasVal = false;
        data.options.forEach((ele: any, i: any) => {
            if (ele.value) {
                index = i;
                hasVal = true;
            }
        });
        if (hasVal) {
            data.control.index = [index];
        } else {
            data.control.index = [];
        }
    } else if (data.type === "多选") {
        const indexs: any = [];
        data.value.forEach((ele: any) => {
            data.options.forEach((item: any, i: any) => {
                if (ele === item) {
                    indexs.push(i);
                }
            });
        });
        if (indexs.length > 0) {
            data.control.index = indexs;
        } else {
            data.control.index = [];
        }
    } else if (data.type === "多选补充文字") {
        const indexs: any = [];
        data.options.forEach((ele: any, i: any) => {
            if (ele.value) {
                indexs.push(i);
            }
        });
        if (indexs.length > 0) {
            data.control.index = indexs;
        } else {
            data.control.index = [];
        }
    }
    return data;
}
/**
   * @description 隐藏或者显示某些相关题目
   */
const hideContent = (controlData: any, data: any) => {
    let arr: any = [];
    if (controlData.control.index.length === 0) {
        // 循环改值
        controlData.control.all.forEach((ele: any) => {
            const index = data.findIndex((val: any) => {
                return ele === val.id;
            });
            data[index]['hide'] = !controlData.control.hide;
            // 如果是隐藏需要清除值
            if (!controlData.control.hide) {
                clearVal(data[index]);
            }
        });
    } else {
        // 拼接数组
        controlData.control.index.forEach((ele: any) => {
            arr = arr.concat(controlData.control.list[ele]);
        });
        // 去重
        arr = Array.from(new Set(arr));
        controlData.control.all.forEach((ele: any) => {
            const index = data.findIndex((val: any) => {
                return ele == val.id;
            });
            const i = arr.findIndex((val: any) => {
                return ele == val;
            });
            if (i === -1) {
                // 找不到给hide相反的值
                data[index]['hide'] = !controlData.control.hide;
                // 如果是隐藏需要清除值
                if (!controlData.control.hide) {
                    clearVal(data[index]);
                }
            } else {
                // 找到给hide的值
                data[index]['hide'] = controlData.control.hide;
                // 如果是隐藏需要清除值
                if (controlData.control.hide) {
                    clearVal(data[index]);
                }
            }
        });
    }
    return data;
}
/**
 * @description 清除隐藏题目的value
 * @param data{}
 * @returns 
 */
const clearVal = (data: any) => {
    if (data.type === "计分单选" || data.type === "sf36计分单选") {
        data.value = {};
    } else if (data.type === "单选补充文字" || data.type === "多选补充文字") {
        data.options.forEach((ele: any) => {
            ele.value = false;
            if (ele.content) {
                ele.content = "";
            }
        });
    } else if (data.type === "多选" || data.type === "动态数组") {
        data.value = [];
    } else {
        data.value = "";
    }
}
/**
 * @description 清除filter
 * @param data{}
 * @returns 
 */
const clearFilter = () => {
    localStorage.setItem("zhanghuguanliFilter", "");
    localStorage.setItem("tongueFilter", "");
    localStorage.setItem("eyeFilter", "");
    localStorage.setItem("handFilter", "");
    localStorage.setItem("pulseFilter", "");
    localStorage.setItem("biduiFilter", "");
    localStorage.setItem("controlFilter", "");
    localStorage.setItem("markFilter", "");
    localStorage.setItem("mark2Filter", "");
    localStorage.setItem("caseFilter", "");
    localStorage.setItem("topicFilter", "");
    localStorage.setItem("poolFilter", "");
    localStorage.setItem("hisListFilter", "");
}
export {
    getNavLeftText, IfEmpty, getControlData, hideContent, clearVal,
    clearFilter, getStrArr
};